import moment from "moment";
import md5 from "md5";

export default {
  install(Vue) {
    Vue.prototype.$helper = {
      skeletonConfig: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0
      },
      tableFooterConfig: {
        itemsPerPageOptions: [5, 10, 20, 50, 100, -1]
      },
      monthDiff: function (d1, d2) {
        var a = moment(d1);
        var b = moment(d2);
        return b.diff(a, "months");
      },
      encryptPass: function (password) {
        const salt = process.env.VUE_APP_SALT;
        password = md5(password);
        password = password.substring(0, 8) + salt + password.substring(8);
        password = md5(password);
        return password;
      },
      currencyFormat: function (
        num,
        prefix = "Rp. ",
        thousandSeparator = ".",
        decimalSeparator = ","
      ) {
        if (isNaN(num)) {
          return 0;
        }
        num = +num;
        if (decimalSeparator === thousandSeparator) {
          thousandSeparator = ".";
          decimalSeparator = ",";
        }
        return (
          num
            ? [
                prefix,
                (num % 1 === 0
                  ? num
                  : (num / 1).toFixed(2).replace(".", decimalSeparator)
                )
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + thousandSeparator)
              ].filter((d) => d)
            : [prefix, 0]
        ).join(prefix ? " " : "");
      },
      sleep: function (ms) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, ms);
        });
      },
      getYearOptions: function () {
        var thisYear = new Date().getFullYear();
        var start = 2020;
        var end = thisYear + 1;
        var arrYear = [];
        for (let i = start; i <= end; i++) {
          arrYear.push("" + i);
        }

        return arrYear;
      },
      formatString: function (...params) {
        let str = params[0];
        let args = params.slice(1);

        let temp = str.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] != "undefined" ? args[number] : match;
        });

        return temp;
      },
      minDateFromYear: function (year) {
        return new Date(Date.UTC(year, 0, 1)).toISOString();
      },
      maxDateFromYear: function (year) {
        return new Date(Date.UTC(year, 11, 31)).toISOString();
      },
      getRandomInt: function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      recordStatusList: function () {
        return ["ACTIVE", "INACTIVE"];
      }
    };
    Vue.prototype.$moment = moment;

    Vue.prototype.$getAsset = (asset) => {
      return process.env.VUE_APP_API_URL + "/asset/preview/" + asset;
    };

    Vue.prototype.$formatDate = (
      string = undefined,
      format = "DD MMM YYYY"
    ) => {
      if (string == undefined) return moment().format(format);
      if (+moment(string).format("YYYYMMDD") === 10101) return "-";
      return moment(string).format(format);
    };

    Vue.prototype.$toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    Vue.prototype.$downloadFile = (response) => {
      /**
       * headers: source of file type and name
       * body: source of binary file
       * status: should 200
       * eg: this.$downloadFile(axios response)
       */
      const { headers, data, status } = response;
      if (status === 200) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        var blob = new Blob([bytes], {
          type: headers["content-type"]
        });

        var fileName = "";
        var disposition = headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            fileName = matches[1].replace(/['"]/g, "");
        }

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        setTimeout(() => {
          link.remove();
        }, 1500);
      }
    };
  }
};
