import dtMenu from "@/data/menu.json";

const state = () => ({});

const mutations = {};

const getters = {
  getMyMenu: (state, getters, rootState) => {
    const role = +(rootState.user.info.role_id || 0);
    const res = dtMenu.filter(
      (x) => x.role.length === 0 || x.role.includes(role)
    );
    return res;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
