import axios from "axios";
import router from "@/router";
import store from "@/store";
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ADMIN,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// http.defaults.timeout = 10000;
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("_token");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
        case 403:
          localStorage.removeItem("_token");
          router.replace({
            path: "/login"
          });
          break;

        case 404:
          alert("page not exist");
          break;

        case 502:
          store.commit("toggleSnack", {
            message: error.response.data || "Bad Gateway",
            show: true,
            color: "red darken-3"
          });
          break;

        default:
          // if (
          //   typeof error.response.data != "undefined" &&
          //   error.response.data.indexOf("Mod jwt error") > -1
          // ) {
          //   localStorage.removeItem("_token");
          //   setTimeout(() => {
          //     router.replace({
          //       path: "/login",
          //       query: {
          //         redirect: router.currentRoute.fullPath
          //       }
          //     });
          //   }, 1000);
          // } else {
          // store.commit("toggleSnack", {
          //   message:
          //     (error.response.data ? error.response.data.message : "") ||
          //     "Something went wrong",
          //   show: true,
          //   color: "red darken-3"
          // });
          // }
          // break;
          return error.response;
      }
      return Promise.reject(error.response);
    }
  }
);

export default {
  install(Vue) {
    Vue.prototype.$http = http;
  }
};
