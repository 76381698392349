<template>
  <v-app-bar
    app
    color="#FFFFFF"
    class="sd-appbar"
    :light="true"
    :clipped-left="true"
  >
    <div class="d-flex align-center">
      <div class="d-flex align-center ml-4 pointer" @click="$router.push('/')">
        <v-img
          class="shrink mr-4"
          contain
          :src="require(`@/assets/images/scar-logo.jpg`)"
          width="128"
        />
        <!-- <span class="app-title text-subtitle-1">Scarlet</span> -->
      </div>
    </div>

    <v-spacer></v-spacer>

    <v-menu bottom rounded offset-y max-width="256px">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="mr-4" rounded>
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list class="pb-0">
          <v-list-item class="pl-7 pr-10" @click="logout">
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="'Sign Out'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AppBar",
  data: () => ({
    timeoutGetNotification: null,
    downloadingManual: false,
    dataBtn: [
      {
        title: "Gerdal OPT",
        to: "/app/gerdal/",
        btn_shadow: "rgba(53, 175, 171, 0.1)",
        btn_color: "linear-gradient(-180deg, #38b1ae 0%, #11968e 100%)"
      },
      {
        title: "Klinik PHT",
        to: "/app/klinikpht/",
        btn_shadow: "rgba(33, 116, 179, 0.1)",
        btn_color: "linear-gradient(-180deg, #2179ba 0%, #235897 100%)"
      },
      {
        title: "PPHT",
        to: "/app/ppht/",
        btn_shadow: "rgba(176, 190, 197, 0.1)",
        btn_color: "linear-gradient(-180deg, #78909C 0%, #455A64 100%)"
      },
      {
        title: "DPI",
        to: "/app/dpi/",
        btn_shadow: "rgba(186, 104, 200, 0.1)",
        btn_color: "linear-gradient(-180deg, #9C27B0 0%, #6A1B9A 100%)"
      }
    ]
  }),
  props: {
    home: {
      default: false
    }
  },
  mounted() {
    this.runGetNotification();
  },
  components: {},
  computed: {
    ...mapState({
      info: (state) => state.user.info,
      notifications: (state) => state.notification.notifications,
      permission: (state) => state.permission.myPermission
    }),
    imageChar: function () {
      if (this.info.Name) {
        return this.info.Name.match(/\b(\w)/g)
          .join("")
          .substr(0, 2);
      }
      return "NA";
    },
    notificationCount: function () {
      return this.notifications.filter((x) => !x.IsRead).length;
    }
  },
  methods: {
    ...mapActions("notification", {
      getNotification: "getData"
    }),
    runGetNotification: async function () {
      if (this.timeoutGetNotification) {
        clearTimeout(this.timeoutGetNotification);
      }
      await this.getNotification();
      let self = this;
      this.timeoutFilter = setTimeout(() => {
        self.runGetNotification();
      }, 30 * 1000);
    },
    logout: function () {
      localStorage.removeItem("_token");
      this.$router.replace("/login");
    },
    notificationClickHandler: async function (item) {
      if (item.DailyWorksheetSummaryID) {
        const to = `/home?id=${item.DailyWorksheetSummaryID}`;
        if (this.$route.fullPath !== to) {
          this.$router.replace(to);
        }
      }
      if (item.IsRead) {
        return;
      }
      let response = await this.$http.post("/auth/my/notification/setread", {
        ids: [item._id]
      });
      let {
        data: { Success }
      } = response;
      if (Success) {
        this.getNotification();
      }
    },
    readAllNotificationHandler: async function () {
      const token = localStorage.getItem("_token");
      if (!token) {
        return;
      }
      let ids = [];
      this.notifications.forEach((x) => {
        if (!x.IsRead) {
          ids.push(x._id);
        }
      });

      let response = await this.$http.post("/auth/my/notification/setread", {
        ids: ids
      });
      let {
        data: { Success }
      } = response;
      if (Success) {
        this.getNotification();
      }
    },
    goTo: function (to) {
      this.$router.push(to);
    },
    downloadManual: async function () {
      let isAdmin = true;
      for (let i = 0; i < this.permission.length; i++) {
        for (let sub = 0; sub < this.permission[i].SubModules.length; sub++) {
          for (
            let ep = 0;
            ep < this.permission[i].SubModules[sub].Endpoints.length;
            ep++
          ) {
            if (!this.permission[i].SubModules[sub].Endpoints[ep].Allowed) {
              isAdmin = false;
              break;
            }
          }
          if (!isAdmin) {
            break;
          }
        }
        if (!isAdmin) {
          break;
        }
      }
      let fileName = isAdmin
        ? "ajuna_usermanual_admin"
        : "ajuna_usermanual_end_user";

      window.location.href = `${process.env.VUE_APP_API_URL}/asset/download/documentation/${fileName}.pdf`;
    }
  }
};
</script>
