<template>
  <v-app>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top right app>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          small
          v-bind="attrs"
          @click="toggleSnack({ ...snackbar, show: false })"
        >
          <v-icon>mdi-close-box</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-main class="pa-0 main-auth">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "AuthLayout",
  data() {
    return {};
  },
  computed: {
    ...mapState(["snackbar"])
  },
  methods: {
    ...mapMutations(["toggleSnack"])
  }
};
</script>
<style>
.main-auth:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/pink-background.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
</style>
