// import Vue from "vue";

const state = () => ({
  notifications: []
});

const mutations = {
  updateNotification(state, data) {
    state.notifications = [...data];
  }
};

const actions = {
  getData: async function () {
    const token = localStorage.getItem("_token");
    if (!token) {
      return;
    }
    // try {
    //   let response = await Vue.prototype.$http.post(
    //     "/auth/my/notification/gets"
    //   );
    //   let {
    //     data: { Success, Data }
    //   } = response;
    //   if (Success) {
    //     context.commit("updateNotification", [...Data].reverse());
    //   } else {
    //     context.commit("updateNotification", []);
    //   }
    // } catch (error) {
    //   context.commit("updateNotification", []);
    // }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
