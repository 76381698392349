import Vue from "vue";
import { compress, decompress } from "lz-string";

const state = () => ({
  provinsi: [],
  kota: []
});

const mutations = {
  updateProvinsi(state, { data, skipStorage }) {
    state.provinsi = [...data];
    if (!skipStorage) {
      setTimeout(() => {
        localStorage.setItem("_p", compress(JSON.stringify(data)));
      }, 10);
    }
  },
  updateKota(state, { data, skipStorage }) {
    state.kota = [...data];
    if (!skipStorage) {
      setTimeout(() => {
        localStorage.setItem("_c", compress(JSON.stringify(data)));
      }, 10);
    }
  }
};

const getters = {
  getProvinsiById: (state) => (id) => {
    const obj = state.provinsi.find((x) => x.id == id);
    if (obj) {
      return obj;
    }
    return {};
  }
};

const actions = {
  getProvinsi: async function ({ commit }) {
    if (localStorage.getItem("_p")) {
      let str = decompress(localStorage.getItem("_p"));
      try {
        const arr = JSON.parse(str);
        commit("updateProvinsi", { data: arr, skipStorage: true });
      } catch {
        // continue
      }
    }
    const token = localStorage.getItem("_token");
    if (!token) {
      return;
    }
    try {
      let response = await Vue.prototype.$http.get(
        `${process.env.VUE_APP_API_URL}/areas/provinces?page=all`
      );
      let {
        data: { success, data }
      } = response;
      if (success) {
        commit("updateProvinsi", { data: data });
      } else {
        commit("updateProvinsi", { data: [] });
      }
    } catch (error) {
      commit("updateProvinsi", { data: [] });
    }
  },
  getKota: async function ({ commit }) {
    if (localStorage.getItem("_c")) {
      let str = decompress(localStorage.getItem("_c"));
      try {
        const arr = JSON.parse(str);
        commit("updateKota", { data: arr, skipStorage: true });
      } catch {
        // continue
      }
    }
    const token = localStorage.getItem("_token");
    if (!token) {
      return;
    }
    try {
      let response = await Vue.prototype.$http.get(
        `${process.env.VUE_APP_API_URL}/areas/cities?page=all`
      );
      let {
        data: { success, data }
      } = response;
      if (success) {
        commit("updateKota", { data: data.data });
      } else {
        commit("updateKota", { data: [] });
      }
    } catch (error) {
      commit("updateKota", { data: [] });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
