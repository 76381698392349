<template>
  <v-navigation-drawer
    app
    permanent
    clipped
    color="white"
    :width="275"
    class="scar-drawer"
  >
    <div class="d-flex flex-column">
      <div class="d-flex px-4 flex-column">
        <v-list class="mn-wrapper">
          <template v-for="(nav, idx) in navItems">
            <v-list-item
              v-if="!(nav.submenu && nav.submenu.length)"
              :key="`nav-mn-${idx}`"
              @click="() => goTo(nav.path)"
              :class="{ 'is-selected': $route.name === nav.id }"
            >
              <v-list-item-icon>
                <v-icon>{{ nav.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ nav.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :key="`nav-mn-${idx}`"
              :value="!!nav.submenu.find((x) => x.id === $route.name)"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ nav.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ nav.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(navC, idxC) in nav.submenu"
                class="pl-9"
                :key="`nav-mn-${idx}-${idxC}`"
                @click="() => goTo(navC.path)"
                :class="{ 'is-selected': $route.name === navC.id }"
                :value="$route.name === navC.id"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon small>{{ navC.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ navC.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item
                v-for="(navC, idxC) in nav.submenu"
                :key="`navs-mn-${idx}-${$idxC}`"
                @click="() => goTo(navC.path)"
                :class="{'is-selected': $route.name === navC.id}"
              >
                <v-list-item-icon>
                  <v-icon>{{ navC.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ navC.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list-group>
          </template>
        </v-list>
        <!-- <div
          v-for="(nav, idx) in navItems"
          class="col-12 pa-2"
          :key="`nav-mn-${idx}`"
        >
          <menu-box
            :is-horizontal="true"
            :icon="nav.icon"
            :title="nav.title"
            :is-selected="
              $route.name === nav.id || getRouteOnIndex(1).name === nav.id
            "
            @click="() => goTo(nav.path)"
          />
        </div> -->
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "NavigationDrawer",
  components: {},
  data: () => ({
    selectedNavItem: null
  }),
  computed: {
    ...mapState({
      info: (state) => state.user.info
    }),
    ...mapGetters("permission", ["getMyMenu"]),
    navItems: function () {
      return this.getMyMenu;
    }
  },
  methods: {
    getRouteOnIndex: function (idx) {
      if (this.$route.matched[idx]) {
        return this.$route.matched[idx];
      } else {
        return {};
      }
    },
    goTo: function (to) {
      if (!to) {
        return;
      }
      this.$router.push(to);
    }
  }
};
</script>
<style scoped>
.v-list-item.is-selected {
  background-color: #f8e8ed;
}
</style>
