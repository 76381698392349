import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "AppHome",
    component: () => import(/* webpackChunkName: "home" */ "@/views/home"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login"),
    meta: {
      layout: "layout-auth",
      guest: true
    }
  },
  {
    path: "/productcategory",
    name: "AppProductCategory",
    component: () =>
      import(
        /* webpackChunkName: "productcategory" */ "@/views/productcategory"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/product",
    name: "AppProduct",
    component: () =>
      import(/* webpackChunkName: "product" */ "@/views/product"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/product-transaction",
    name: "AppProductTranaction",
    component: () =>
      import(
        /* webpackChunkName: "product-transaction" */ "@/views/product-transaction"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/reseller",
    name: "AppUserReseller",
    component: () =>
      import(/* webpackChunkName: "reseller" */ "@/views/reseller"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/shipment",
    name: "AppShipment",
    component: () =>
      import(/* webpackChunkName: "shipment" */ "@/views/shipment"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "*",
    redirect: { name: "AppHome" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/" : "/",
  routes
});

const checkPermission = (to) => {
  const mn = store.getters["permission/getMyMenu"];
  const allowedMenuIDs = mn.map((x) => x.id);
  if (mn.length) {
    if (to.matched[1]) {
      return allowedMenuIDs.includes(to.matched[1].name);
    }
    return true;
  }
  return true;
};

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("_token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else if (!checkPermission(to)) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("_token") == null) {
      next();
    } else {
      next({
        path: "/"
      });
    }
  } else {
    next();
  }
});

export default router;
