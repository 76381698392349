export default {
  install(Vue) {
    Vue.prototype.$alert = {
      delete: async function (
        self,
        {
          text = "Do you really want to delete this item?",
          title = "Warning",
          type
        } = {}
      ) {
        const res = await self.$dialog.confirm({
          text,
          title,
          type,
          actions: {
            false: "Cancel",
            true: {
              color: "red",
              text: "Yes I do"
            }
          }
        });
        return res;
      },
      confirm: async function (
        self,
        { text, title = "Confirmation", actions } = {}
      ) {
        let objParam = {
          text,
          title
        };
        if (actions) {
          objParam = {
            ...objParam,
            actions
          };
        }
        const res = await self.$dialog.confirm(objParam);
        return res;
      }
    };
  }
};
