import Vue from "vue";
import Vuex from "vuex";
import permission from "./modules/permission";
import user from "./modules/user";
import notification from "./modules/notification";
import location from "./modules/location";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    snackbar: {
      message: "default message",
      color: "red",
      show: false
    },
    dialogModal: {
      title: "",
      text: "",
      type: "success",
      show: false
    }
  },
  mutations: {
    toggleSnack(state, payload) {
      state.snackbar = { ...payload };
    },
    showDialog(state, payload) {
      state.dialogModal = {
        ...state.dialogModal,
        ...payload
      };
    }
  },
  actions: {},
  modules: {
    permission,
    user,
    notification,
    location
  }
});

export default store;
