<template>
  <v-dialog v-model="showDialog" :max-width="600" :persistent="true">
    <v-card class="dialog-modal">
      <v-card-title
        class="d-flex justify-space-between align-center modal-header py-2 pr-2"
      >
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text align="center">
        <v-icon color="secondary" style="font-size: 115px">{{ icon }}</v-icon>
        <p class="font-weight-bold text-h6 mt-3">{{ dialogModal.title }}</p>
        <p class="font-weight-normal text-body-2">{{ dialogModal.text }}</p>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn color="secondary" @click="close()"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "DialogModal",
  data: () => ({
    showDialog: false
  }),
  props: {
    show: {
      default: false
    }
  },
  watch: {
    "dialogModal.show": function (val) {
      this.showDialog = val;
    }
  },
  computed: {
    ...mapState(["dialogModal"]),
    icon: function () {
      switch (this.dialogModal.type) {
        case "success":
          return "mdi-check-circle-outline";
        default:
          return "mdi-information-variant";
      }
    }
  },
  methods: {
    ...mapMutations({
      showDialogMutation: "showDialog"
    }),
    close: function () {
      this.showDialogMutation({
        show: false
      });
    }
  }
};
</script>

<style>
.dialog-modal .v-card__actions {
  justify-content: center;
}
</style>
