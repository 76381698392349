import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import http from "./plugins/http";
import helper from "./plugins/helper";
import alert from "./plugins/alert";
import "./assets/scss/styles.scss";
import "./plugins/vuetify-dialog";
import validation from "./plugins/validation";

import LayoutDefault from "@/layouts/default.vue";
import LayoutAuth from "@/layouts/auth.vue";

Vue.config.productionTip = false;

Vue.use(http);
Vue.use(helper);
Vue.use(validation);

Vue.component("LayoutDefault", LayoutDefault);
Vue.component("LayoutAuth", LayoutAuth);

Vue.use(alert);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
