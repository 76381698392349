<template>
  <v-app>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top right app>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          small
          v-bind="attrs"
          @click="toggleSnack({ ...snackbar, show: false })"
        >
          <v-icon>mdi-close-box</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <navigation-drawer />
    <app-bar />

    <v-main>
      <template v-if="isAppReady">
        <router-view />
        <dialog-modal />
      </template>
      <v-overlay :absolute="true" :value="!isAppReady" opacity="0.2">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DialogModal from "@/components/atom/dialog-modal";
import AppBar from "@/components/organism/AppBar";
import NavigationDrawer from "@/components/organism/NavigationDrawer";

export default {
  name: "LayoutDefault",
  components: {
    DialogModal,
    AppBar,
    NavigationDrawer
  },
  data: () => ({
    isLoading: false
  }),
  mounted() {
    this.refreshToken();
  },
  computed: {
    ...mapState(["snackbar"]),
    isAppReady: function () {
      return !this.isLoading;
    }
  },
  methods: {
    ...mapActions("location", ["getProvinsi", "getKota"]),
    ...mapMutations(["toggleSnack"]),
    loadLocationData: function () {
      return Promise.all([this.getProvinsi(), this.getKota()]);
    },
    getMyProfile: async function () {
      const token = localStorage.getItem("_token");
      if (!token) {
        return;
      }
      try {
        let response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/auth/user-profile`
        );
        let {
          data: { success, data, message }
        } = response;
        if (success) {
          this.$store.commit("user/updateMyInfo", { ...data });
        } else {
          this.$dialog.notify.error(message, {
            position: "top-right",
            timeout: 3000
          });
        }
      } catch (error) {
        this.$dialog.notify.error(error, {
          position: "top-right",
          timeout: 3000
        });
      }
    },
    refreshToken: async function () {
      const token = localStorage.getItem("_token");
      if (!token) {
        return;
      }
      try {
        const _t1 = localStorage.getItem("_t") || 0;
        const _t2 = new Date().getTime();
        const diff = _t2 - _t1;
        const _8hour = 8 * 60 * 60 * 1000;
        if (diff >= _8hour) {
          let {
            data: { success, data }
          } = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/auth/refresh`
          );
          if (success) {
            localStorage.setItem("_token", data.token);
            localStorage.setItem("_t", new Date().getTime());
            await this.$helper.sleep(1000);
          } else {
            localStorage.removeItem("_token");
            this.$router.replace({
              path: "/login"
            });
            // this.$dialog.notify.error(message, {
            //   position: "top-right",
            //   timeout: 3000
            // });
          }
        } else {
          // console.log('')
        }
      } catch (error) {
        this.$dialog.notify.error(error, {
          position: "top-right",
          timeout: 3000
        });
      } finally {
        await this.loadLocationData();
        this.getMyProfile();
      }
    }
  }
};
</script>
