const state = () => ({
  info: {}
});

const mutations = {
  updateMyInfo(state, data) {
    state.info = { ...data };
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
